import { React } from 'react'
import { products } from './workdata.jsx'
import { useParams } from 'react-router-dom'
import { HeaderTemplate } from './../components/HeaderTemplate.jsx'
import { OverviewTemplate } from '../components/OverviewTemplate.jsx'
import { VideoTemplate } from './../components/VideoTemplate.jsx'
import { TextTemplate } from './../components/TextTemplate.jsx'
import { ImageTemplate } from './../components/ImageTemplate.jsx'

export const Portfolio = () => {
    const { client } = useParams()

    return (
        <>
            {products
                .filter((items) => items.client === client)
                .map((items) => {
                    return (
                        <div key={items.id} className="relative">
                            <HeaderTemplate
                                clientName={items.client}
                                headerExt={items.headerExt}
                            />
                            <OverviewTemplate
                                clientName={items.clientName}
                                desc={items.desc}
                                skills1={items.skills1}
                                skills2={items.skills2}
                                skills3={items.skills3}
                                skills4={items.skills4}
                                summaryBody={items.summaryBody}
                                bgColor={items.bgColor}
                                fontColor={items.fontColor}
                            />

                            <div className="text-white">
                                {[...Array(items.templates.length)].map(
                                    (e, i) =>
                                        items.templates[i][0] ===
                                        'VideoTemplate' ? (
                                            <div key={i}>
                                                <VideoTemplate
                                                    url={items.templates[i][1]}
                                                    bgColor={
                                                        items.templates[i][2]
                                                    }
                                                />
                                            </div>
                                        ) : items.templates[i][0] ===
                                          'TextTemplate' ? (
                                            <div key={i}>
                                                <TextTemplate
                                                    line1={
                                                        items.templates[i][1]
                                                    }
                                                    line2={
                                                        items.templates[i][2]
                                                    }
                                                    line3={
                                                        items.templates[i][3]
                                                    }
                                                    line4={
                                                        items.templates[i][4]
                                                    }
                                                    bgColor={
                                                        items.templates[i][5]
                                                    }
                                                    fontColor={
                                                        items.templates[i][6]
                                                    }
                                                />
                                            </div>
                                        ) : items.templates[i][0] ===
                                          'ImageTemplate' ? (
                                            <div key={i}>
                                                <ImageTemplate
                                                    imgName={
                                                        items.templates[i][1]
                                                    }
                                                    bgColor={
                                                        items.templates[i][2]
                                                    }
                                                />
                                            </div>
                                        ) : null
                                )}
                            </div>
                        </div>
                    )
                })}
        </>
    )
}
