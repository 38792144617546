import React from 'react'
import { useParams } from 'react-router-dom'
import { AdvancedImage } from '@cloudinary/react'
import { Cloudinary } from '@cloudinary/url-gen'

export function ImageTemplate(props) {
    const { client } = useParams()

    // Create a Cloudinary instance and set your cloud name.
    const cld = new Cloudinary({
        cloud: {
            cloudName: 'dcbukrokd',
        },
    })

    const myImage = cld.image(
        'portfolio/' + client + '/' + props.imgName + '.png'
    )

    return (
        <section
            style={{
                backgroundColor: props.bgColor,
            }}
        >
            <div className="flex max-w-7xl container mx-auto px-4 py-12 relative justify-center items-center">
                <AdvancedImage cldImg={myImage} />
            </div>
        </section>
    )
}
