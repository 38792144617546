export const products = [
    {
        id: 1,
        client: 'kodak',
        clientName: 'Kodak',
        skills1: 'Design',
        skills2: 'User Experience Strategy',
        skills3: 'Prototyping (React Native)',
        skills4: 'Experiental Activation',
        summaryBody:
            'I worked with Kodak to prototype the new Kodak Moments App UI (React Native) and to create a unique, physical / digital experience at SXSW.',
        bgColor: '#3c3c3c',
        fontColor: '#fff',
        templates: [
            [
                'VideoTemplate',
                'https://player.vimeo.com/video/164779697?autoplay=1&title=0&byline=0&portrait=0',
                '#000', // bgcolor
            ],
            [
                'VideoTemplate',
                'https://player.vimeo.com/video/745832871?autoplay=1&title=0&byline=0&portrait=0 ',
                '#bbb', // bgcolor
            ],
        ],
    },
    {
        id: 2,
        client: 'ebay',
        clientName: 'Ebay',
        skills1: 'Product Design',
        skills2: 'Prototyping (React)',
        skills3: 'FB Messenger',
        skills4: 'Experiental App',
        summaryBody:
            'I worked with Ebay to design deliver new product experiences and explore new business territories.',
        bgColor: '#3c3c3c',
        fontColor: '#fff',
        templates: [
            [
                'WorkIntro',
                'Introducing Shopbot',
                'Finding the next 100 million user for the world’s largest marketplace by means blending brand, product and commerce.  Along with design partners, We reset Ebay’s brand language and delivered new experiences like a virtual shopping assistant while exploring new business territories with product and brand teams.',
                '#fff',
                'dark',
                '#fff',
                '#000',
            ],
            [
                'VideoTemplate',
                'https://player.vimeo.com/video/751963268?autoplay=1&title=0&byline=0&portrait=0',
                '#bbb',
            ],
            [
                'TextTemplate',
                '"How you set this up was hugely valuable because we now have ideas and methodologies that have never before existed in meaningful ways across our company or in commerce."',
                'RJ Pittman',
                'Chief Product Officer',
                'Ebay',
                '#FFDFD2',
                '#000',
            ],
            [
                'VideoTemplate',
                'https://player.vimeo.com/video/751944989?autoplay=1&title=0&byline=0&portrait=0',
                '#3c3c3c',
            ],

            ['ImageTemplate', 'ebay-bloomberg', '#fff'],
        ],
    },
    {
        id: 3,
        client: 'amnesty',
        clientName: 'Amnesty International',
        skills1: 'Web VR',
        skills2: 'React',
        skills3: 'UX Design',
        summaryBody:
            'Raising awareness of the ongoing tragedy in the Syrian civil war',
        bgColor: '#F2F2F2',
        fontColor: '#000',
        templates: [
            [
                'VideoTemplate',
                'https://player.vimeo.com/video/745774512?autoplay=1&title=0&byline=0&portrait=0',
                '#000', // bgcolor
            ],
        ],
    },
    {
        id: 4,
        client: 'stndby',
        clientName: 'Stndby',
        skills1: 'Product Design',
        skills2: 'Conversational AI',
        skills3: 'React',
        summaryBody:
            'Stndby is a modern health and wellness company built around an AI assistant, powered by blockchain.',
        bgColor: '#000',
        fontColor: '#fff',
        templates: [
            ['ImageTemplate', 'stndby-2', '#fff'],
            [
                'TextTemplate',
                '“You’ll never get to new places by going the same route as everyone else. That’s why it was deeply important to partner with a developer that fundamentally understands design and technology and how they work together as a living breathing system.”',

                'Sashka Rothchild',
                'CEO & Founder',
                'Stndby',
                '#fff',
                '#000',
            ],
            ['ImageTemplate', 'stndby-1', '#fff'],
        ],
    },
]
