import { React, useLayoutEffect } from 'react'
import { Routes, Route, BrowserRouter, useLocation } from 'react-router-dom'
import { NavBar } from './components/NavBar.jsx'
import { Footer } from './components/Footer.jsx'
import { About } from './pages/About.jsx'
import { Contact } from './pages/Contact.jsx'
import { Home } from './pages/Home.jsx'
import { Portfolio } from './pages/Portfolio.jsx'

const Wrapper = ({ children }) => {
    const location = useLocation()
    useLayoutEffect(() => {
        document.documentElement.scrollTo(0, 0)
    }, [location.pathname])
    return children
}

function App() {
    return (
        <>
            <NavBar />
            <BrowserRouter>
                <Wrapper>
                    <Routes>
                        <Route path="/" element={<Home />} />
                        <Route path="/about" element={<About />} />
                        <Route
                            path="/portfolio/:client"
                            element={<Portfolio />}
                        />
                        <Route path="/contact" element={<Contact />} />
                        <Route path="*" element={<Home />} />
                    </Routes>
                </Wrapper>
            </BrowserRouter>
            <Footer />
        </>
    )
}

export default App
