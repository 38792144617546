import React from 'react'
import robbie from '../img/robbie.png'
import { DownArrow } from '../components/DownArrow.jsx'
import { motion } from 'framer-motion'

export const Hero = () => {
    return (
        <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ duration: 0.5 }}
        >
            <section className="bg-black">
                <div className="h-screen flex flex-col justify-center">
                    <div className="container flex flex-col justify-center p-2 mx-auto sm:py-12 lg:py-24 lg:flex-row">
                        <div className="flex items-center justify-center p-6 mt-8 lg:mt-0 h-72 sm:h-80 lg:h-96 xl:h-112 2xl:h-128">
                            <img
                                src={robbie}
                                alt=""
                                className="object-contain h-72 sm:h-80 lg:h-96 xl:h-112 2xl:h-128 rounded-lg"
                            />
                        </div>

                        <div className="flex flex-col justify-center p-2 text-center rounded-sm lg:max-w-md xl:max-w-lg lg:text-left">
                            <span className="text-5xl font-bold leading-none sm:text-6xl header">
                                Hi, I&#39;m Robbie. I&#39;m a UX and Design Tech
                                leader.
                            </span>
                            <span className="mt-6 mb-8 text-lg sm:mb-12 subHeadline">
                                I&#39;ve spent my career at the intersection of
                                ideas, product design and engineering.
                            </span>
                            <div className="flex flex-col space-y-4 sm:items-center sm:justify-center sm:flex-row sm:space-y-0 sm:space-x-4 lg:justify-start -xs:hidden">
                                <div className="flex justify-center items-center">
                                    <DownArrow />
                                </div>
                                <span className="ml-4 subJeadline text-white">
                                    Sccoll down to see my work
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </motion.div>
    )
}
