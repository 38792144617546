import { useEffect, React } from 'react'
import { DownArrow } from '../components/DownArrow.jsx'

export function OverviewTemplate(props) {
    useEffect(() => {
        // 👇️ set style on body element
        // document.body.style.backgroundColor = props.bgColor
        document.body.style.color = props.fontColor
    })

    return (
        <>
            <div className="relative">
                <div className="absolute bottom-0 left-1/2 mb-10">
                    <DownArrow />
                </div>
            </div>
            <section
                className="px-4 flex h-screen"
                id="overview"
                style={{
                    backgroundColor: props.bgColor,
                    color: props.fontColor,
                }}
            >
                <div className="flex container mx-auto px-4 relative justify-center items-center">
                    <div className="flex flex-wrap">
                        <div className="w-full lg:mb-0 lg:w-7/12">
                            <h2 className="workDescSm mt-5">
                                Working with {props.clientName}
                            </h2>
                        </div>
                        <div className="w-full lg:w-5/12 workDescSm mt-5">
                            {props.skills1}
                            <br />
                            {props.skills2}
                            <br />
                            {props.skills3}
                            <br />
                            {props.skills4}
                        </div>
                        <div className="mt-20 w-full lg:w-10/12 workDescLg">
                            {props.summaryBody}
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}
