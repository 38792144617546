import React from 'react'
import { useParams } from 'react-router-dom'

export function HeaderTemplate() {
    const { client } = useParams()

    const imageBase = 'https://res.cloudinary.com/dcbukrokd/portfolio/'
    const imageCover = imageBase + client + '-cover.png'
    return (
        <div
            style={{
                backgroundImage: 'url(' + imageCover + ')',
                backgroundPosition: '50% 35%',
                backgroundRepeat: 'no-repeat',
                backgroundSize: 'cover',
                height: '100vh',
                width: '100vw',
                padding: '0px',
            }}
        />
    )
}
