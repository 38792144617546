import React from 'react'

export const About = () => {
    return (
        <section className="px-4 bg-black text-white mx-auto max-w-7xl relative py-40">
            <div className="flex flex-wrap">
                <div className="md:w-5/12 aboutMeLg">About Me</div>
                <div className="md:w-5/12 aboutMeSm">
                    With 15+ hands-on years building web, mobile and
                    experiential solutions, I enjoy solving complex user
                    experience problems at scale and advocating for design in a
                    collaborative dev environment. I&#39;ve also co-founded two
                    product design tech agencies.
                </div>
                <div className="md:w-2/12 aboutMeSm"></div>
                {/* <div className="flex flex-wrap my-20 py-20"> */}
                <div className="md:w-2/12 pt-20">
                    <svg
                        width="48px"
                        height="1px"
                        viewBox="0 0 48 1"
                        version="1.1"
                        xmlns="http://www.w3.org/2000/svg"
                    >
                        <g
                            id="Updated-Screens"
                            stroke="none"
                            strokeWidth="1"
                            fill="none"
                            fillRule="evenodd"
                            strokeLinecap="square"
                        >
                            <g
                                id="Studio"
                                transform="translate(-154.000000, -897.000000)"
                                stroke="#FFFFFF"
                            >
                                <g
                                    id="work-details"
                                    transform="translate(154.000000, 889.000000)"
                                >
                                    <path
                                        d="M0.5,8.5 L46.6735855,8.5"
                                        id="Line-4"
                                    />
                                </g>
                            </g>
                        </g>
                    </svg>
                </div>
                <div className="md:w-4/12 aboutMeXs pt-20">
                    Say hello
                    <br />
                    robbiew@gmail.com
                </div>
                <div className="md:w-3/12 aboutMeXs pt-20">
                    Opportunity
                    <br />
                    I&#39;m looking for passionate and inspiring humans to work
                    with. Those who want to invent the future, not repeat the
                    past.
                </div>
            </div>
            {/* </div> */}
        </section>
    )
}
