import { useEffect, React } from 'react'

export function TextTemplate(props) {
    useEffect(() => {
        // 👇️ set style on body element
        // document.body.style.backgroundColor = props.bgColor
        document.body.style.color = props.fontColor
    })

    return (
        <>
            <section
                className="px-4 flex h-screen"
                id="overview"
                style={{
                    backgroundColor: props.bgColor,
                    color: props.fontColor,
                }}
            >
                <div className="flex max-w-7xl container mx-auto px-4 relative justify-center items-center">
                    <div className="flex flex-wrap">
                        <div className="w-full lg:mb-0 lg:w-9/12">
                            <h2 className="textTemplateLg mt-5">
                                {props.line1}
                            </h2>
                        </div>

                        <div className="mt-20 w-full lg:w-12/12 textTemplateSm">
                            {props.line2}
                            <br />
                            {props.line3}
                            <br />
                            {props.line4}
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}
