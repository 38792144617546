import React from 'react'
import devilHorns from '../img/devilHornsAnimated.gif'

export const Footer = () => {
    return (
        <footer className="w-full text-center lg:text-left bg-black text-white">
            <div className="justify-between  mx-auto lg:max-w-7xl md:items-center md:flex md:container md:mx-auto w-full py-10 text-center md:text-left">
                <div className="grid grid-1 md:grid-cols-2 lg:grid-cols-4 gap-8">
                    <div className="">
                        <h6
                            className="
            uppercase
            font-semibold
            mb-4
            flex
            items-center
            justify-center
            md:justify-start
          "
                        >
                            <svg
                                aria-hidden="true"
                                focusable="false"
                                data-prefix="fas"
                                data-icon="cubes"
                                className="w-4 mr-3"
                                role="img"
                                xmlns="http://www.w3.org/2000/svg"
                                viewBox="0 0 512 512"
                            >
                                <path
                                    fill="currentColor"
                                    d="M488.6 250.2L392 214V105.5c0-15-9.3-28.4-23.4-33.7l-100-37.5c-8.1-3.1-17.1-3.1-25.3 0l-100 37.5c-14.1 5.3-23.4 18.7-23.4 33.7V214l-96.6 36.2C9.3 255.5 0 268.9 0 283.9V394c0 13.6 7.7 26.1 19.9 32.2l100 50c10.1 5.1 22.1 5.1 32.2 0l103.9-52 103.9 52c10.1 5.1 22.1 5.1 32.2 0l100-50c12.2-6.1 19.9-18.6 19.9-32.2V283.9c0-15-9.3-28.4-23.4-33.7zM358 214.8l-85 31.9v-68.2l85-37v73.3zM154 104.1l102-38.2 102 38.2v.6l-102 41.4-102-41.4v-.6zm84 291.1l-85 42.5v-79.1l85-38.8v75.4zm0-112l-102 41.4-102-41.4v-.6l102-38.2 102 38.2v.6zm240 112l-85 42.5v-79.1l85-38.8v75.4zm0-112l-102 41.4-102-41.4v-.6l102-38.2 102 38.2v.6z"
                                ></path>
                            </svg>
                            HARD SKILLS
                        </h6>
                        <p>
                            HTML/CSS, React, React Native, React-Motion, Go,
                            Python, 3D Modeling, Unity. Plus: Design
                            facilitation, Design/Dev advocacy and more!
                        </p>
                    </div>
                    <div className="">
                        <h6 className="uppercase font-semibold mb-4 flex justify-center md:justify-start">
                            GET IN TOUCH
                        </h6>
                        <p className="">robbiew@gmail.com</p>
                        <p className="flex items-center justify-center md:justify-start mb-4">
                            Berkeley, CA{' '}
                        </p>
                    </div>

                    <div className=""> </div>
                    <div className="">
                        <h6 className="mb-4 flex justify-center md:justify-start">
                            &copy;2022 Robbie Whiting
                        </h6>

                        <p>
                            {' '}
                            <img src={devilHorns} alt="devil horns" />
                        </p>
                    </div>
                </div>
            </div>
        </footer>
    )
}
