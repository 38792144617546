import { React, useEffect } from 'react'
import { Link } from 'react-router-dom'
import Cover from 'react-video-cover'
import imgWorkday from '../img/ourWorkWorkday.png'
import imgPolarized from '../img/ourWorkPolarized.png'
import imgEbay from '../img/ourWorkEbay.png'
import imgAmnesty from '../img/ourWorkAmnesty.png'
import imgStndby from '../img/ourWorkStndby.png'
import { Hero } from '../components/Hero.jsx'
import LazyShow from '../components/LazyShow'
import AmnestyLogo from '../img/client-logos/amnesty-logo.svg'
import PeetsLogo from '../img/client-logos/peets-logo.svg'
import EbayLogo from '../img/client-logos/ebay-logo.svg'
import NokiaLogo from '../img/client-logos/nokia-logo.svg'
import ViacomLogo from '../img/client-logos/viacom-logo.svg'
import MethodLogo from '../img/client-logos/method-logo.svg'
import GoogleLogo from '../img/client-logos/google-logo.svg'
import StndbyLogo from '../img/client-logos/stndby-logo.svg'
import VwLogo from '../img/client-logos/vw-logo.svg'

const kodakVideo = {
    src: '/kodak_exit_screen_final.mp4',
    autoPlay: true,
    muted: true,
    loop: true,
    playsInline: true,
}

const LazyShowWrapper = () => {
    useEffect(() => {
        // change background color with a random color
        const color = 'black'
        document.body.style.background = color
    })
    return (
        <div className="mx-auto max-w-7xl justify-center items-center w-full relative">
            <Hero />
            <h1 className="md:ml-0 ml-4 pb-5 missionSm text-white header">
                Portfolio
            </h1>

            <div className="gap-6 md:columns-3 space-y-6 pt-3 text-white mb-10">
                <LazyShow>
                    <img className="" src={imgWorkday} alt="" />
                    <div className="subHeadline pl-12 md:pr-48 pt-4 pr-24">
                        <Link to="/portfolio/workday">
                            <span className="text-yellow-100">
                                Workday&#39;s
                            </span>{' '}
                            Enterprise Design System ↗️
                        </Link>
                    </div>
                </LazyShow>
                <LazyShow>
                    <img className="" src={imgPolarized} alt="" />
                    <div className="subHeadline pl-12 md:pr-48 pt-4 pr-24">
                        <Link to="/portfolio/polarized">
                            Weekend Project:{' '}
                            <span className="text-yellow-400">
                                Polarized! News ↗️
                            </span>
                        </Link>
                    </div>
                </LazyShow>
                <LazyShow>
                    <img className="" src={imgEbay} alt="" />
                    <div className="subHeadline pl-12 md:pr-48 pt-4 pr-24">
                        <Link to="/portfolio/ebay">
                            Deliver the next 100 million users on{' '}
                            <span className="text-lime-500">Ebay</span>
                        </Link>
                    </div>
                </LazyShow>
                <LazyShow>
                    <img className="" src={imgAmnesty} alt="" />
                    <div className="subHeadline pl-12 md:pr-48 pt-4 pr-24">
                        <Link to="/portfolio/amnesty">
                            Web VR with{' '}
                            <span className="text-rose-400">
                                Amnesty International
                            </span>
                        </Link>
                    </div>
                </LazyShow>
                <LazyShow>
                    <Cover videoOptions={kodakVideo} remeasureOnWindowResize />
                    <div className="subHeadline pl-12 md:pr-48 pt-4 pr-24">
                        <Link to="/portfolio/kodak">
                            SXSW installation and app rekindles{' '}
                            <span className="text-sky-400">Kodak Moments</span>
                        </Link>
                    </div>
                </LazyShow>
                <LazyShow>
                    <img className="" src={imgStndby} alt="" />
                    <div className="subHeadline pl-12 md:pr-48 pt-4 pr-24">
                        <Link to="/portfolio/stndby">
                            Rebuilding the human operating system with{' '}
                            <span className="text-lime-500">Stndby</span>
                        </Link>
                    </div>
                </LazyShow>
            </div>
            <div className="flex flex-wrap my-20 py-20 md:px-0 px-4">
                <div className="md:w-5/12 missionSm md:my-0 my-0">Mission</div>
                <div className="md:w-3/12 missionSm">
                    To help progressive companies solve the world&#39;s toughest
                    business challenges through design technology.
                </div>
                <div className="md:w-10/12 aboutMeMd pt-20 mt-20 md:px-0 px-4">
                    &quot;Make it simple, keep it human and aim for scale.&quot;
                </div>
                <div className="md:w-2/12"></div>
                <div className="md:w-12/12 aboutMeLg mt-2">
                    - Robbie&#39;s Proverb
                </div>
            </div>
            <div className="container mx-auto gap-20 grid grid-cols-1 md:gap-20 md:grid md:grid-cols-3 mb-20 pb-20">
                <div className="w-full inline-flex justify-center">
                    <img src={AmnestyLogo} alt="" />
                </div>
                <div className="w-full inline-flex justify-center">
                    <img src={PeetsLogo} alt="" />
                </div>
                <div className="w-full inline-flex justify-center">
                    <img src={EbayLogo} alt="" />
                </div>
                <div className="w-full inline-flex justify-center">
                    <img src={NokiaLogo} alt="" />
                </div>
                <div className="w-full inline-flex justify-center">
                    <img src={ViacomLogo} alt="" />
                </div>
                <div className="w-full inline-flex justify-center">
                    <img src={MethodLogo} alt="" />
                </div>
                <div className="w-full inline-flex justify-center">
                    <img src={GoogleLogo} alt="" />
                </div>
                <div className="w-full inline-flex justify-center">
                    <img src={StndbyLogo} alt="" />
                </div>
                <div className="w-full inline-flex justify-center">
                    <img src={VwLogo} alt="" />
                </div>
            </div>
        </div>
    )
}
export const Home = () => {
    return (
        <>
            <LazyShowWrapper />
        </>
    )
}
