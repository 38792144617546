import React from 'react'

export const DownArrow = () => {
    return (
        <svg
            width="30px"
            height="38px"
            viewBox="0 0 30 38"
            version="1.1"
            xmlns="http://www.w3.org/2000/svg"
        >
            <g
                id="final-setup"
                stroke="none"
                strokeWidth="1"
                fill="none"
                fillRule="evenodd"
            >
                <g
                    id="homepage"
                    transform="translate(-705.000000, -2511.000000)"
                    stroke="#fff"
                >
                    <g
                        id="Group-2"
                        transform="translate(705.000000, 2505.000000)"
                    >
                        <g
                            id="down-arrow-white"
                            transform="translate(7.000000, 0.000000)"
                            strokeLinecap="square"
                        >
                            <g
                                id="down-arrow"
                                transform="translate(8.500000, 26.775000) rotate(-90.000000) translate(-8.500000, -26.775000) translate(5.022727, 19.125000)"
                            >
                                <path
                                    d="M0.248376623,7.37678571 L6.58076287,0.411160843"
                                    id="Line-2"
                                    strokeWidth="2"
                                />
                                <path
                                    d="M0.248376623,15.0267857 L6.58076287,8.06116084"
                                    id="Line-2-Copy"
                                    strokeWidth="2"
                                    transform="translate(3.477273, 11.475000) scale(-1, 1) translate(-3.477273, -11.475000) "
                                />
                            </g>
                        </g>
                        <rect
                            id="Rectangle-9"
                            strokeWidth="1.25"
                            x="0.625"
                            y="6.625"
                            width="28.75"
                            height="36.75"
                            rx="14.375"
                        />
                    </g>
                </g>
            </g>
        </svg>
    )
}
