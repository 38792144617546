import React from 'react'
import ReactPlayer from 'react-player'

export function VideoTemplate(props) {
    return (
        <section
            className="md:pb-20 py-20"
            id="overview"
            style={{ backgroundColor: props.bgColor }}
        >
            <div className="mx-auto max-w-4xl justify-center items-center w-full">
                <div className="player-wrapper ">
                    <ReactPlayer
                        className="react-player"
                        url={props.url}
                        allow="autoplay; fullscreen;"
                        width="100%"
                        height="100%"
                    />
                </div>
            </div>
        </section>
    )
}
